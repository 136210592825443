import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.primary.main
                        : theme.palette.primary.dark,
                color: 'white',
            }}
        >
            <Container maxWidth="sm">
                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>
                    🥨 Claudius' Oktoberfest Tischanmeldung 🥨
                </Typography>
                <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                    © 2024 Claudius Seitz. Alle Rechte vorbehalten.
                </Typography>
                <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                    Mit <span role="img" aria-label="beer">🍺</span> entwickelt in München
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;