import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Paper, Grid, Link } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

function Login({ setIsAuthenticated, setUserRole }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://wiesn-signup.onrender.com/api/users/login', { email, password });
            console.log('Login response:', response.data);
            localStorage.setItem('token', response.data.token);
            console.log('Token stored in localStorage:', localStorage.getItem('token'));

            // Update authentication state
            setIsAuthenticated(true);
            setUserRole(response.data.role);

            // Navigate to the events page or dashboard
            navigate('/events');
        } catch (error) {
            console.error('Login error:', error.response ? error.response.data : error.message);
            setError(error.response?.data?.message || 'Login failed. Please try again.');
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Typography variant="h4" component="h1" gutterBottom align="center">
                        Login
                    </Typography>
                    {error && <Typography color="error" gutterBottom align="center">{error}</Typography>}
                    <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                        <Button
                            component={RouterLink}
                            to="/register"
                            variant="outlined"
                            color="secondary"
                            fullWidth
                        >
                            I hob koan Account
                        </Button>
                    </Box>
                    <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                        <Link component={RouterLink} to="/forgot-password">
                            Passwort vagessn?
                        </Link>
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Login;