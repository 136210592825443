import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Card, CardContent, Grid, Box, Chip, CardMedia, IconButton, Tooltip } from '@mui/material';
import { AccessTime, LocationOn, LocalBar, Restaurant } from '@mui/icons-material';

function MyEvents() {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://wiesn-signup.onrender.com/api/orders/user', {
                    headers: { 'x-auth-token': token }
                });
                setOrders(response.data);
            } catch (error) {
                console.error('Failed to fetch orders', error.response ? error.response.data : error.message);
            }
        };
        fetchOrders();

        // Set up interval to update countdowns
        const interval = setInterval(() => {
            setOrders(prevOrders => prevOrders.map(order => ({
                ...order,
                countdown: getCountdown(new Date(order.event.date))
            })));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const getCountdown = (eventDate) => {
        const now = new Date();
        const difference = eventDate - now;

        if (difference < 0) return 'Event has started!';

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    const getEventImage = (tent) => {
        if (tent === 'Schottenhamel-Festhalle') {
            return "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2023-08/schottenhamel_fassade_sebastian_lehner-06907.jpg?h=56d0ca2e";
        } else if (tent === 'Pschorr-Bräurosl') {
            return "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2024-07/20230918-a7404437-bearbeitetmrm_foto.jpg?h=9e42eb47";
        }
        return "https://via.placeholder.com/400x200?text=Oktoberfest+Event";
    };

    return (
        <Box sx={{ py: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
                Mein Vollrausch            </Typography>
            <Grid container spacing={4}>
                {orders.map(order => (
                    <Grid item xs={12} sm={6} md={4} key={order._id}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={getEventImage(order.event.tent)}
                                alt={order.event.name}
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" component="div" gutterBottom>
                                    {order.event.name}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Tooltip title="Date & Time">
                                        <IconButton size="small">
                                            <AccessTime fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant="body2" color="text.secondary">
                                        {new Date(order.event.date).toLocaleString()}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <Tooltip title="Location">
                                        <IconButton size="small">
                                            <LocationOn fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant="body2" color="text.secondary">
                                        {order.event.tent}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                    <Tooltip title="Beers">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LocalBar fontSize="small" sx={{ mr: 1 }} />
                                            <Typography variant="body2">{order.beers}</Typography>
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title="Food">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Restaurant fontSize="small" sx={{ mr: 1 }} />
                                            <Typography variant="body2">{order.food}</Typography>
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Chip
                                    label={order.countdown}
                                    color="primary"
                                    sx={{ width: '100%', justifyContent: 'center', mt: 'auto' }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default MyEvents;