import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia, Chip, Paper } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';


function HomePage() {
    const [events, setEvents] = useState([]);
    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        // Fetch events
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://wiesn-signup.onrender.com/api/events');
                setEvents(response.data);
            } catch (error) {
                console.error('Failed to fetch events', error);
            }
        };
        fetchEvents();

        // Set up countdown
        const targetDate = new Date('2024-09-21T11:00:00');
        const timer = setInterval(() => {
            const now = new Date();
            const difference = targetDate - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setCountdown({ days, hours, minutes, seconds });
            } else {
                clearInterval(timer);
                setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    return (
        <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: 'primary.main', color: 'secondary.main' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    🥨 Countdown zur Oktoberfest-Eröffnung 🥨
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    {['Tage', 'Stunden', 'Minuten', 'Sekunden'].map((unit, index) => (
                        <Box key={unit} sx={{ textAlign: 'center', m: 2 }}>
                            <Typography variant="h3" component="div">
                                {Object.values(countdown)[index]}
                            </Typography>
                            <Typography variant="h6">{unit}</Typography>
                        </Box>
                    ))}
                </Box>
                <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                    21. September 2024, 11:00 Uhr
                </Typography>
            </Paper>
            <Paper elevation={3} sx={{ p: 4, mb: 4, color: 'primary.main' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    I gfrei mi auf eich!
                    Damit ich besser planen kann, meldets euch bitte an. 🍺🥨
                </Typography>
            </Paper>

            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                Upcoming Events
            </Typography>


            <Grid container spacing={4}>
                {events.map((event) => (
                    <Grid item xs={12} md={6} key={event._id}>
                        <Link to={`/event/${event._id}`} style={{ textDecoration: 'none' }}>
                            <Card sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                transition: '0.3s',
                                '&:hover': {
                                    transform: 'scale(1.03)',
                                    boxShadow: '0 4px 20px rgba(0,0,0,0.12)',
                                },
                            }}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={event.tent === "Schottenhamel-Festhalle"
                                        ? "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2023-08/schottenhamel_fassade_sebastian_lehner-06907.jpg?h=56d0ca2e"
                                        : "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2024-07/20230918-a7404437-bearbeitetmrm_foto.jpg?h=9e42eb47"}
                                    alt={event.tent}
                                />
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {event.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Datum: {new Date(event.date).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Uhrzeit: {event.time}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Zelt: {event.tent}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Leute: {event.capacity}
                                    </Typography>
                                    <Typography variant="body2" paragraph>
                                        {event.description}
                                    </Typography>
                                    <Chip label={`${event.availableSpots} Plätze übrig`} color="primary" />
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                ))}
            </Grid>

            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                Unsere Zelte
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2024-07/20230918-a7404437-bearbeitetmrm_foto.jpg?h=9e42eb47"
                            alt="Bräurosl"
                        />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Pschorr-Bräurosl
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Dieses Jahr ohne Josef Menzel dafür mit Karolinenfelder und Volxxbeat.                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                • Beer: Hacker-Pschorr
                            </Typography>
                            <Typography variant="body2">
                                • Sitzplätze: 8,450 (6,200 drinnen, 2,250 draussen)
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2023-08/schottenhamel_fassade_sebastian_lehner-06907.jpg?h=56d0ca2e"
                            alt="Schottenhamel-Festhalle"
                        />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Schottenhamel-Festhalle
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Der Schottenhamel is das älteste und grösste Zelt auf der Wiesn - ehemals auch bekannt als das Würmtalzelt.
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                • Bier: Spaten
                            </Typography>
                            <Typography variant="body2">
                                • Sitzplätze: 10,000 (6,000 drinne, 4,000 draussen)
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default HomePage;