import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Grid,
    CardMedia,
    Box,
    Chip,
    IconButton
} from '@mui/material';
import { AccessTime, LocationOn, People } from '@mui/icons-material';

function EventOverview() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://wiesn-signup.onrender.com/api/events', {
                    headers: { 'x-auth-token': localStorage.getItem('token') }
                });
                setEvents(response.data);
            } catch (error) {
                console.error('Failed to fetch events', error);
            }
        };
        fetchEvents();
    }, []);

    const getEventImage = (tent) => {
        if (tent === 'Schottenhamel-Festhalle') {
            return "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2023-08/schottenhamel_fassade_sebastian_lehner-06907.jpg?h=56d0ca2e";
        } else if (tent === 'Pschorr-Bräurosl') {
            return "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2024-07/20230918-a7404437-bearbeitetmrm_foto.jpg?h=9e42eb47";
        }
        return "https://via.placeholder.com/400x200?text=Oktoberfest+Event";
    };

    return (
        <Box sx={{ py: 4 }}>
            <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
                Oktoberfest Events
            </Typography>
            <Grid container spacing={4}>
                {events.map(event => (
                    <Grid item xs={12} sm={6} md={4} key={event._id}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={getEventImage(event.tent)}
                                alt={event.name}
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" component="div" gutterBottom>
                                    {event.name}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <IconButton size="small" sx={{ mr: 1 }}>
                                        <AccessTime fontSize="small" />
                                    </IconButton>
                                    <Typography variant="body2" color="text.secondary">
                                        {new Date(event.date).toLocaleDateString()} at {event.time}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <IconButton size="small" sx={{ mr: 1 }}>
                                        <LocationOn fontSize="small" />
                                    </IconButton>
                                    <Typography variant="body2" color="text.secondary">
                                        {event.tent}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <IconButton size="small" sx={{ mr: 1 }}>
                                        <People fontSize="small" />
                                    </IconButton>
                                    <Typography variant="body2" color="text.secondary">
                                        {event.availableSpots} / {event.capacity} spots available
                                    </Typography>
                                </Box>
                                <Chip
                                    label={event.availableSpots > 0 ? "Spots Available" : "Fully Booked"}
                                    color={event.availableSpots > 0 ? "success" : "error"}
                                    size="small"
                                />
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    component={Link}
                                    to={`/event/${event._id}`}
                                    variant="contained"
                                    fullWidth
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default EventOverview;