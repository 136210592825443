import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Box,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';



function AdminDashboard() {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filterEvent, setFilterEvent] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        const filtered = orders.filter(order =>
            order.event.name.toLowerCase().includes(filterEvent.toLowerCase())
        );
        setFilteredOrders(filtered);
    }, [filterEvent, orders]);

    const fetchOrders = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://wiesn-signup.onrender.com/api/orders/all', {
                headers: { 'x-auth-token': token }
            });
            setOrders(response.data);
            setFilteredOrders(response.data);
        } catch (error) {
            console.error('Failed to fetch orders', error.response ? error.response.data : error.message);
            showSnackbar('Failed to fetch orders', 'error');
        }
    };

    const handleDeleteClick = (order) => {
        setOrderToDelete(order);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`https://wiesn-signup.onrender.com/api/orders/${orderToDelete._id}`, {
                headers: { 'x-auth-token': token }
            });
            setOrders(orders.filter(order => order._id !== orderToDelete._id));
            setDeleteDialogOpen(false);
            showSnackbar('Order deleted successfully', 'success');
        } catch (error) {
            console.error('Failed to delete order', error.response ? error.response.data : error.message);
            showSnackbar('Failed to delete order', 'error');
        }
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Admin Dashboard - All Signups
            </Typography>
            <TextField
                label="Filter by Event Name"
                variant="outlined"
                value={filterEvent}
                onChange={(e) => setFilterEvent(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Beers</TableCell>
                            <TableCell>Food</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredOrders.map((order) => (
                            <TableRow key={order._id}>
                                <TableCell>{order.user.username}</TableCell>
                                <TableCell>{order.event.name}</TableCell>
                                <TableCell>{new Date(order.event.date).toLocaleDateString()}</TableCell>
                                <TableCell>{order.beers}</TableCell>
                                <TableCell>{order.food}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteClick(order)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this order?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
}

export default AdminDashboard;