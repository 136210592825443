import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import HomePage from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import EventCreation from './components/EventCreation';
import EventOverview from './components/EventOverview';
import EventDetails from './components/EventDetails';
import MyEvents from './components/MyEvents';
import AdminDashboard from './components/AdminDashboard';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Footer from './components/Footer';

import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('userRole');
    if (token) {
      setIsAuthenticated(true);
      setUserRole(role);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    setIsAuthenticated(false);
    setUserRole('');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Claudius Wiesn Anmeldung
              </Typography>
              <Button color="inherit" component={Link} to="/">Home</Button>
              {!isAuthenticated && (
                <>
                  <Button color="inherit" component={Link} to="/login">Login</Button>
                </>
              )}
              {isAuthenticated && userRole === 'admin' && (
                <>
                  <Button color="inherit" component={Link} to="/admin">Admin</Button>
                  <Button color="inherit" component={Link} to="/create-event">Event erstellen</Button>
                </>
              )}
              {isAuthenticated && (
                <>
                  <Button color="inherit" component={Link} to="/events">Events</Button>
                  <Button color="inherit" component={Link} to="/my-events">Mein Rausch</Button>
                  <Button color="inherit" onClick={handleLogout}>Ausloggen</Button>
                </>
              )}
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} setUserRole={setUserRole} />} />
              <Route path="/events" element={isAuthenticated ? <EventOverview /> : <Navigate to="/login" />} />
              <Route path="/event/:id" element={isAuthenticated ? <EventDetails /> : <Navigate to="/login" />} />
              <Route path="/my-events" element={isAuthenticated ? <MyEvents /> : <Navigate to="/login" />} />
              <Route path="/create-event" element={isAuthenticated && userRole === 'admin' ? <EventCreation /> : <Navigate to="/" />} />
              <Route
                path="/admin"
                element={
                  isAuthenticated && userRole === 'admin'
                    ? <AdminDashboard />
                    : <Navigate to="/" />
                }
              />
            </Routes>
          </Container>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;