import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, MenuItem } from '@mui/material';

function EventCreation() {
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [tent, setTent] = useState('');
    const [capacity, setCapacity] = useState('');
    const [description, setDescription] = useState('');

    const tents = [
        'Schottenhamel-Festhalle',
        'Pschorr-Bräurosl',
        'Augustiner-Festhalle',
        'Hofbräu-Festzelt',
        'Hacker-Festzelt',
        'Löwenbräu-Festzelt'
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://wiesn-signup.onrender.com/api/events',
                { name, date, time, tent, capacity: Number(capacity), description },
                {
                    headers: { 'x-auth-token': localStorage.getItem('token') }
                }
            );
            alert('Event created successfully');
            // Clear form fields after successful creation
            setName('');
            setDate('');
            setTime('');
            setTent('');
            setCapacity('');
            setDescription('');
        } catch (error) {
            console.error('Failed to create event:', error.response ? error.response.data : error.message);
            alert('Failed to create event');
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Create Event
            </Typography>
            <TextField
                fullWidth
                margin="normal"
                label="Event Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
            <TextField
                fullWidth
                margin="normal"
                type="date"
                label="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                required
            />
            <TextField
                fullWidth
                margin="normal"
                type="time"
                label="Time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
                required
            />
            <TextField
                fullWidth
                margin="normal"
                select
                label="Tent"
                value={tent}
                onChange={(e) => setTent(e.target.value)}
                required
            >
                {tents.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                fullWidth
                margin="normal"
                type="number"
                label="Capacity"
                value={capacity}
                onChange={(e) => setCapacity(e.target.value)}
                required
            />
            <TextField
                fullWidth
                margin="normal"
                label="Description"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Create Event
            </Button>
        </Box>
    );
}

export default EventCreation;