import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Typography,
    TextField,
    Button,
    Box,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Grid,
    Paper,
    Divider,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { AccessTime, LocationOn, People, LocalBar, Restaurant, Event } from '@mui/icons-material';

function EventDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(null);
    const [beers, setBeers] = useState(0);
    const [food, setFood] = useState(0);
    const [joinBeergarten, setJoinBeergarten] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchEvent = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://wiesn-signup.onrender.com/api/events/${id}`, {
                headers: { 'x-auth-token': token }
            });
            setEvent(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching event:', error);
            setError('Failed to fetch event details');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvent();
    }, [id]);

    const handleSignup = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            await axios.post('https://wiesn-signup.onrender.com/api/orders',
                { event: id, beers, food },
                { headers: { 'x-auth-token': token } }
            );
            fetchEvent();
            navigate('/my-events');
        } catch (error) {
            console.error('Signup error:', error);
            alert(error.response?.data?.message || 'Signup failed');
        }
    };

    const getEventImage = (tent) => {
        if (tent === 'Schottenhamel-Festhalle') {
            return "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2023-08/schottenhamel_fassade_sebastian_lehner-06907.jpg?h=56d0ca2e";
        } else if (tent === 'Pschorr-Bräurosl') {
            return "https://www.oktoberfest.de/sites/default/files/styles/3_2_w1216/public/2024-07/20230918-a7404437-bearbeitetmrm_foto.jpg?h=9e42eb47";
        }
        return "https://via.placeholder.com/400x200?text=Oktoberfest+Event";
    };

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;
    if (!event) return <Typography>No event found</Typography>;

    return (
        <Box sx={{ mt: 3 }}>
            <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                <CardMedia
                    component="img"
                    height="300"
                    image={getEventImage(event.tent)}
                    alt={event.name}
                />
                <CardContent>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {event.name}
                    </Typography>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <Tooltip title="Date">
                                    <IconButton size="small">
                                        <Event />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body1">
                                    {new Date(event.date).toLocaleDateString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <Tooltip title="Time">
                                    <IconButton size="small">
                                        <AccessTime />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body1">{event.time}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <Tooltip title="Location">
                                    <IconButton size="small">
                                        <LocationOn />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body1">{event.tent}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <Tooltip title="Capacity">
                                    <IconButton size="small">
                                        <People />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="body1">
                                    {event.availableSpots} / {event.capacity} spots available
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1" paragraph>
                        {event.description}
                    </Typography>
                    <Chip
                        label={`${event.availableSpots} spots available`}
                        color={event.availableSpots > 0 ? "success" : "error"}
                        sx={{ mb: 2 }}
                    />
                </CardContent>
            </Paper>

            {event.availableSpots > 0 ? (
                <Paper elevation={3} sx={{ mt: 3, p: 3 }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        I bin dabei for this Event
                    </Typography>
                    <Box component="form" onSubmit={handleSignup}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type="number"
                                    label="Number of beers"
                                    value={beers}
                                    onChange={(e) => setBeers(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <LocalBar color="action" sx={{ mr: 1 }} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type="number"
                                    label="Amount of food"
                                    value={food}
                                    onChange={(e) => setFood(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <Restaurant color="action" sx={{ mr: 1 }} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={joinBeergarten}
                                            onChange={(e) => setJoinBeergarten(e.target.checked)}
                                            name="joinBeergarten"
                                        />
                                    }
                                    label={`Ich komm vorher schon in den Biergarten ${event.beergartenTime} (2 Stunden vorher)`}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }} fullWidth>
                            I bin dabei
                        </Button>
                    </Box>
                </Paper>
            ) : (
                <Typography variant="h6" color="error" sx={{ mt: 3 }}>
                    Wir sind leider ausgebucht.
                </Typography>
            )}
        </Box>
    );
}

export default EventDetails;